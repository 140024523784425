import React, { createContext, useState } from "react";

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [lastFilter, setLastFilter] = useState("All");

  return (
    <FilterContext.Provider value={{ lastFilter, setLastFilter }}>
      {children}
    </FilterContext.Provider>
  );
};
