import {
  getUserMessages,
  getUserUnreadMessages,
  markMessageAsRead,
  getMessage,
  getFile,
  verifyAuth,
} from "utils/apiRequestBuilder";
import {
  getAllClients,
  getLoginOptions,
  logOutOptions,
  getAllMessages,
  expireMessages,
  deleteMessages,
  getApplications,
} from "./apiRequestBuilder";

export const fetchUserMessages = async (timestamp) => {
  const [request, options] = await getUserMessages(timestamp);
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const fetchUnreadMessages = async (timestamp) => {
  const [request, options] = await getUserUnreadMessages(timestamp);
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const fetchAllMessages = async () => {
  const [request, options] = await getAllMessages();
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const expireMessage = async (messageIds) => {
  const [request, options] = await expireMessages(messageIds);
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const deleteMessage = async (messageIds) => {
  const [request, options] = await deleteMessages(messageIds);
  return await fetch(request, options);
};

export const fetchApplications = async () => {
  const [request, options] = await getApplications();
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const fetchFile = async (attachmentId) => {
  const [request, options] = await getFile(attachmentId);
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const readMessage = async (messageId) => {
  const [request, options] = await markMessageAsRead(messageId);
  const response = await fetch(request, options);
  if (response.status === 200) {
  } else {
    console.log(response);
  }
};

export const getUserMessage = async (messageId) => {
  const [request, options] = await getMessage(messageId);
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};

export const getUserStatus = async () => {
  const [request, options] = await verifyAuth();
  const response = await fetch(request, options);
  return response;
};

export const login = async (data) => {
  const [request, options] = await getLoginOptions(data);
  const response = await fetch(request, options);
  return response;
};

export const logout = async () => {
  const [request, options] = await logOutOptions();
  const response = await fetch(request, options);
  return response;
};

export const getRecipients = async () => {
  const [request, options] = await getAllClients();
  const response = await fetch(request, options);
  if (response.status === 200) {
    const json = await response.json();
    return json;
  } else {
    console.log(response);
  }
};
