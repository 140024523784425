import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MessageContext } from "utils/context/messageContext";
import Layout from "components/Layout";
import BasicTable from "components/Table";
import useIsMobile from "utils/hooks/mobileHook";
import MobileViewMessages from "components/MobileViewMessages";
import LoaderAnimation from "utils/animation/loader";
import NoMessagesAnimation from "utils/animation/noMessages";
import { fetchUserMessages } from "utils/apiRequests";
import Filter from "components/Messages/filter";
import { FilterContext } from "utils/context/filterContext";
import moment from "moment";

const MessagePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const { lastFilter, setLastFilter } = useContext(FilterContext);
  const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const initialFilter = queryParams.get("filter") || "All";
    if (initialFilter !== lastFilter) {
      setLastFilter(initialFilter);
    }
  }, [location.search, lastFilter, setLastFilter]);

  const handleApplicationChange = (newFilter) => {
    setLastFilter(newFilter);
    navigate(`/messages?filter=${encodeURIComponent(newFilter)}`, { replace: true });
  };

  const fetchInformation = async () => {
    try {
      setIsLoading(true);
      const response = await fetchUserMessages(currentDate);
      const sortedMessages = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setUserMessages(sortedMessages);
      setDataAvailable(sortedMessages.length > 0);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInformation();
  }, [lastFilter]);

  return (
    <Layout>
      {isMobile ? (
        <MobileViewMessages
          messages={userMessages}
          isLoading={isLoading}
          dataAvailable={dataAvailable}
        />
      ) : isLoading ? (
        <LoaderAnimation />
      ) : dataAvailable ? (
        <>
          <Filter handleApplicationChange={handleApplicationChange} />
          <BasicTable
            messages={
              lastFilter === "All"
                ? userMessages
                : userMessages.filter((msg) => msg.application_name === lastFilter)
            }
          />
        </>
      ) : (
        <NoMessagesAnimation />
      )}
    </Layout>
  );
};

export default MessagePage;
