import React, { useState, createContext, useEffect } from "react";
import authService from "utils/AuthenticationService";
import {
  getUserMessages,
  getUserUnreadMessages,
  markMessageAsRead,
} from "utils/apiRequestBuilder";

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [userMessages, updateUserMessages] = useState([]);
  const [unreadMessages, updateUnreadMessages] = useState([]);
  const [activeMessage, setActiveMessage] = useState({});
  const [messagesType, setMessagesType] = useState("unread");

  useEffect(() => {
    console.log("Updated messages successfully");
  }, [userMessages, unreadMessages]);

  return (
    <MessageContext.Provider
      value={{
        messagesType,
        setMessagesType,
        unreadMessages,
        userMessages,
        setActiveMessage,
        activeMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
