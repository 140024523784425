import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MessageContext } from "utils/context/messageContext";
import DOMPurify from "dompurify";

const MessageCard = ({ message }) => {
  const { setActiveMessage } = useContext(MessageContext);
  const navigate = useNavigate();

  const handleClick = () => {
    setActiveMessage(message);
    navigate(`/message/${message.messageid}`);
  };

  // sanitize the html content in message body
  let sanitizedMessage = DOMPurify.sanitize(message.message);
  sanitizedMessage = sanitizedMessage
  .replace(/<br\s*\/?>/gi, ' ')
  .replace(/<\/?p[^>]*>/gi, ' ')
  .replace(/<\/?div[^>]*>/gi, ' ')
  .replace(/\n/gi, ' ')
  .replace(/\r/gi, ' ')
  .replace(/\s\s+/g, ' ')
  .trim();

  return (
    <Card
      sx={{
        maxWidth: 345,
        backgroundColor: "#d8e5f0",
        borderRadius: "20px",
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontWeight: message.is_read ? "normal" : "bold" }}
        >
          {message.application_name}
        </Typography>
        <Typography
          sx={{ fontSize: "18px", fontWeight: message.is_read ? "400" : "bold" }}
        >
          {message.subject}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: message.is_read ? "normal" : "bold",
          }}
          dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
        />
      </CardContent>
    </Card>
  );
};

export default MessageCard;
