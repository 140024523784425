import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { alpha } from "@mui/material/styles";

const EnhancedTableToolbar = (props) => {
  const { numSelected, expireMessageHandler, deleteMessageHandler } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Manage Messages
        </Typography>
      )}
      {numSelected > 0 ? (
        <Box sx={{ display: "flex" }}>
          <Tooltip title="Expire Selected Messages">
            <Button
              onClick={() => {
                expireMessageHandler();
              }}
            >
              Expire
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                deleteMessageHandler();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            {/* <FilterListIcon /> */}
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
