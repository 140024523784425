import {
  Box,
  IconButton,
  Typography,
  Toolbar,
  CssBaseline,
  AppBar,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Button,
  Badge,
  Skeleton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import TuneIcon from "@mui/icons-material/Tune";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { getMessageCount, getUserRoles } from "utils/apiRequestBuilder";
import { MessageContext } from "utils/context/messageContext";
import useIsMobile from "utils/hooks/mobileHook";
import { AuthContext } from "utils/context/authContext";
import { FilterContext } from "utils/context/filterContext";
import moment from "moment";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  height: "75px",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Layout = ({ window, children }) => {
  const { handleLogout } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [messageCount, setMessageCount] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const isMobile = useIsMobile();
  const { setMessagesType } = useContext(MessageContext);
  const navigate = useNavigate();
  const { lastFilter } = useContext(FilterContext);
  const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
  const WriteRole = "MessageCentreWriter";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const fetchMessageCount = async () => {
    const [request, options] = await getMessageCount(currentDate);
    const response = await fetch(request, options);
    if (response.status === 200) {
      const json = await response.json();
      setMessageCount(json.data[0].count);
    } else {
      console.log(response);
    }
  };

  const fetchUserRoles = async () => {
    const [request, options] = await getUserRoles();
    const response = await fetch(request, options);
    if (response.status === 200) {
      const json = await response.json();
      setUserRoles(json.data.map((role) => role.name));
      setLoading(false); // Set loading to false after fetching roles
    } else {
      console.log(response, "ERROR FETCHING USER ROLES");
      setUserRoles([]);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const clickHandler = (messageType) => {
    setMessagesType(messageType);
    if (messageType === "newmessage") {
      navigate("/newMessage");
    } else {
      navigate(`/messages?filter=${encodeURIComponent(lastFilter)}`);
    }

    if (isMobile) {
      handleDrawerToggle();
    }
  };

  useEffect(() => {
    fetchUserRoles();
    fetchMessageCount();
  }, []);

  const drawer = (
    <Box sx={{ height: "100%" }}>
      <DrawerHeader>
        <img
          src={"/wsp_RGB.png"}
          style={{
            height: "60px",
            width: "120px",
            padding: 3,
          }}
        />
      </DrawerHeader>
      <Divider />
      {loading &&
        [1, 2].map((item) => (
          <ListItem>
            <ListItemButton>
              {" "}
              <Skeleton
                key={item}
                variant="text"
                width={200}
                height={30}
                sx={{ fontSize: "1rem" }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      {!loading && (
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            height: isMobile ? "80%" : "90%",
            alignItems: "center",
          }}
        >
          {userRoles.includes(WriteRole) && (
            <>
              <ListItem id="manageMessages" disablePadding>
                <ListItemButton
                  onClick={() => {
                    navigate("/manage-messages");
                  }}
                >
                  <ListItemIcon>
                    <TuneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Messages" />
                </ListItemButton>
              </ListItem>
            </>
          )}
          
          <ListItem id="allMessages" disablePadding>
            <ListItemButton onClick={() => clickHandler("all")}>
              <ListItemIcon>
                {messageCount > 0 ? (
                  <Badge color="primary" badgeContent={messageCount}>
                    <InboxIcon />
                  </Badge>
                ) : (
                  <InboxIcon />
                )}
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </ListItemButton>
          </ListItem>
          <Button
            sx={{ mt: "auto" }}
            color="primary"
            variant="contained"
            onClick={() => handleLogout()}
          >
            Log Out
          </Button>
        </List>
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "#d8e5f0",
          color: "#000",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Toolbar sx={{ height: "75px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ ml: { xs: 0, md: 4 } }}
          >
            Messages
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
              backgroundColor: "#d8e5f0",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: "none",
              backgroundColor: "#d8e5f0",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
