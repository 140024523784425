import React, { useContext } from 'react';
import { Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import { FilterContext } from "utils/context/filterContext";

const useStyles = makeStyles((theme) => ({
    flexEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
}));

const Filter = ({ handleApplicationChange }) => {
    const classes = useStyles();
    const { lastFilter, setLastFilter } = useContext(FilterContext);

    const handleChange = (event) => {
        const newFilter = event.target.value;
        setLastFilter(newFilter);
        handleApplicationChange(newFilter);
    };

    return (
        <Box className={classes.flexEnd}>
            <FormControl className={classes.formControl} variant="standard">
                <InputLabel id="filter-messages-label">Filter Messages</InputLabel>
                <Select
                    labelId="filter-messages-label"
                    id="filter-messages"
                    value={lastFilter}
                    IconComponent={() => <FilterListIcon />}
                    label="applicationName"
                    onChange={handleChange}
                >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Mobile Weather Portal"}>Mobile Weather Portal</MenuItem>
                    <MenuItem value={"Web Weather Portal"}>Web Weather Portal</MenuItem>
                    <MenuItem value={"Decision Vue"}>Decision Vue</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default Filter;