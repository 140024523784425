import { Box, IconButton, Typography, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getUserMessage } from "utils/apiRequests";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { readMessage } from "utils/apiRequests";
import useIsMobile from "utils/hooks/mobileHook";
import * as DOMPurify from "dompurify";
import Attachment from "../components/Messages/attachment";

const ViewMessagePage = () => {
  const isMobile = useIsMobile();
  const [message, setMessage] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchMessage = async () => {
    setIsLoading(true);
    const response = await getUserMessage(id);
    const { data } = response;
    setMessage(data);
    if (data.messageid && !data.is_read) {
      readMessage(id);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMessage();
  }, []);

  useEffect(() => {
    const msgBody = document.getElementById("msgBody");
    if (msgBody) {
      const clean = DOMPurify.sanitize(message.message, {
        USE_PROFILES: { html: true },
      });

      msgBody.innerHTML = clean;
    }
  }, [isLoading]);

  return (
    <Layout>
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: "white",
          height: "calc(100vh - 90px)",
          p: 3,
          mx: { xs: 2, md: 4 },
        }}
      >
        <Box>
          <IconButton
            sx={{ padding: 0, color: "black" }}
            onClick={() => navigate("/messages")}
          >
            {isLoading ? (
              <Skeleton
                variant="text"
                width={60}
                height={50}
                sx={{ fontSize: "1rem" }}
              />
            ) : (
              <>
                <ArrowBackIcon />
                <Typography>Back</Typography>
              </>
            )}
          </IconButton>
        </Box>

        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          {isLoading ? (
            <Skeleton
              variant="text"
              width={100}
              height={30}
              sx={{ fontSize: "1rem" }}
            />
          ) : (
            <Typography variant="h5">
              <strong>{message.subject}</strong>
            </Typography>
          )}

          <Box sx={{ maxHeight: 540, overflowY: "auto" }}>
            {isLoading ? (
              [1, 2, 3].map(() => (
                <Box sx={{ marginTop: 2 }}>
                  <Skeleton
                    variant="rounded"
                    width={isMobile ? 320 : 1000}
                    // height={}
                    sx={{ fontSize: "1rem" }}
                  />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 2,
                }}
              >
                <div id="msgBody"></div>
              </Box>
            )}
          </Box>
          <Divider />
          <Box sx={{ display: "flex" }}>
            {message.attachments &&
              message.attachments.length > 0 &&
              message.attachments.map((attachment) => (
                <Attachment attachment={attachment} />
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={100}
                  height={30}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <>
                  <Typography>
                    <strong>From Application</strong>
                  </Typography>
                  <Typography>{message.application_name}</Typography>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {isLoading ? (
                <Skeleton
                  variant="text"
                  width={100}
                  height={30}
                  sx={{ fontSize: "1rem" }}
                />
              ) : (
                <>
                  <Typography>
                    <strong>Date</strong>
                  </Typography>
                  <Typography>{message.date}</Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default ViewMessagePage;
