import React, { useState, useMemo } from "react";
import {
  Chip,
  Alert as MuiAlert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import EnhancedTableHead from "./enhancedTableHead";
import EnhancedTableToolbar from "./enhancedTableToolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { expireMessage, deleteMessage } from "utils/apiRequests";
import { useNavigate } from "react-router-dom";

const ManageMessagesTable = ({
  messages,
  setOpen,
  setMessage,
  setAlertConfig,
  setMessages,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const navigate = useNavigate();

  const handleExpireMessages = async (messageIds) => {
    try {
      await expireMessage(messageIds);
      setAlertConfig({
        show: true,
        severity: "success",
        message: "Messages Expired Successfully",
      });
    } catch (error) {
      console.log(error);
      setAlertConfig({
        show: true,
        severity: "error",
        message: "Error Expiring Messages",
      });
    }
  };

  const editMessageHandler = (message) => {
    navigate("/newMessage", {
      state: {
        isEdit: true,
        messageId: message.id,
        messageData: {
          ...message,
          clients: message.clients.map((client) => ({
            value: client.Identifier,
            label: client.Name,
          })),
          applications: message.applications.map((app) => ({
            value: app.id,
            label: app.label,
          })),
        },
      },
    });
  };

  const handleDeleteMessages = async (messageIds) => {
    try {
      const response = await deleteMessage(messageIds);
      if (response.status === 200) {
        setMessages((prevMessages) =>
          prevMessages.filter(
            (message) => !messageIds.includes(message.message_data.id)
          )
        );
        setAlertConfig({
          show: true,
          severity: "success",
          message: "Messages Deleted Successfully",
        });
      } else {
        setAlertConfig({
          show: true,
          severity: "error",
          message: "Error Deleting Messages",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const visibleMessages = useMemo(
    () =>
      [...messages].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, messages]
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = visibleMessages.map(
        (message) => message.message_data.id
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messages.length) : 0;

  return (
    <>
      <EnhancedTableToolbar
        numSelected={selected.length}
        expireMessageHandler={() => {
          handleExpireMessages(selected);
        }}
        deleteMessageHandler={() => {
          handleDeleteMessages(selected);
        }}
      />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={visibleMessages.length}
          />
          <TableBody>
            {visibleMessages.map((row, index) => {
              const message = row.message_data;
              const isItemSelected = selected.includes(message.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={message.id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, message.id)}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell>{message.created_at}</TableCell>
                  <TableCell>{message.subject}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        message.clients.length > 0
                          ? message.clients.map((client) => `${client.Name} `)
                          : "No Recipients"
                      }
                    >
                      {message.clients.length > 1 ? (
                        <Chip label={`${message.clients.length} Recipients`} />
                      ) : (
                        <Chip
                          label={
                            message.clients.length > 0
                              ? message.clients[0].Name
                              : "No Recipients"
                          }
                        />
                      )}
                    </Tooltip>
                  </TableCell>
                  <TableCell>{message.expires_at}</TableCell>
                  <TableCell>{message.type}</TableCell>
                  <TableCell>
                    <MuiAlert
                      severity={
                        message.severity === "normal"
                          ? "info"
                          : message.severity
                      }
                    >
                      {message.severity}
                    </MuiAlert>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <Tooltip title="View Message">
                        <IconButton
                          onClick={() => {
                            setMessage(message);
                            setOpen(true);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Message">
                        <IconButton
                          onClick={() => {
                            editMessageHandler(message);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Message">
                        <IconButton
                          onClick={() => {
                            handleDeleteMessages([message.id]);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Expire Message">
                        <IconButton
                          onClick={() => {
                            handleExpireMessages([message.id]);
                          }}
                        >
                          <EventBusyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={messages.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ManageMessagesTable;
