// PrivateRoute.js

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "utils/context/authContext";

const PrivateRoute = ({ children }) => {
  const { userAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  return userAuthenticated ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
