import React, { useState, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserStatus, login, logout } from "utils/apiRequests";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userAuthenticated, setUserAuthentication] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    try {
      const response = await login(data);
  
      if (response.status === 200) {
        await response.json();
        setUserAuthentication(true);
        return { success: true };
      } else if (response.status === 401) {
        return { success: false, message: "Invalid username or password." };
      } else {
        return { success: false, message: "Login failed. Please try again." };
      }
    } catch (error) {
      console.error("Error during login:", error);
      return { success: false, message: "Network error. Please try again later." };
    }
  };  

  const handleLogout = async () => {
    try {
      const response = await logout();
      if (response.status === 200) {
        setUserAuthentication(false);
        navigate("/");
      } else {
        console.error("Error logging out");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const verifyAuth = async () => {
    try {
      const response = await getUserStatus();
      if (response.status === 200) {
        setUserAuthentication(true);
      } else {
        setUserAuthentication(false);
      }
    } catch (error) {
      console.error("Error while verifying user:", error);
      setUserAuthentication(false);
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      await verifyAuth();
    };
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handleLogin,
        handleLogout,
        userAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
