import * as React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MessageCard from "./messageCard";
import { MessageContext } from "utils/context/messageContext";
import LoaderAnimation from "utils/animation/loader";
import NoMessagesAnimation from "utils/animation/noMessages";

const MobileViewMessages = ({ messages, isLoading, dataAvailable }) => {
  const { messagesType } = React.useContext(MessageContext);
  return (
    <>
      {isLoading ? (
        <LoaderAnimation />
      ) : dataAvailable ? (
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Typography variant="h5">
            {messagesType === "unread" ? "Unread Messages" : "All Messages"}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {messages.map((msg) => (
                <Box mt={2}>
                  <MessageCard message={msg} />
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <NoMessagesAnimation />
      )}
    </>
  );
};

export default MobileViewMessages;
