import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#ff372f', // your primary color
      },
      secondary: {
        main: '#d8e5f0', // your secondary color
      },
    },
});

export default theme;