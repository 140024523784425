import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DownloadIcon from "@mui/icons-material/Download";
import { fetchFile } from "../../utils/apiRequests";
import pdfFileIcon from "../../assets/images/pdf-file.png"; // Replace with the actual path to your PDF file icon

const Attachment = ({ attachment }) => {
  const handleDownloadFile = async (attachmentId, fileName) => {
    const response = await fetchFile(attachmentId);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = response.url;
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <Box sx={{ width: "auto", ml: "20px" }}>
      <Card
        sx={{
          backgroundColor: "transparent",
          height: "60px",
          border: "1px solid gray",
        }}
      >
        <CardContent sx={{ display: "flex", justifyContent: "space-between" }}>
          <img src={pdfFileIcon} width={"30px"} height={"30px"} />
          <Typography sx={{ ml: "5px", fontSize: "16px" }}>
            {attachment.file_name}
          </Typography>
          <Divider orientation="vertical" />
          <DownloadIcon
            sx={{ cursor: "pointer" }}
            onClick={() =>
              handleDownloadFile(attachment.id, attachment.file_name)
            }
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default Attachment;
