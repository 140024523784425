import React from 'react';
import Lottie from 'react-lottie';
import animationData from 'assets/animations/no-messages';
import { Box, Typography } from '@mui/material';

const NoMessagesAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "70vh"}}>
        <Lottie 
          options={defaultOptions}
          height={200}
          width={200}
        />
        <Typography variant='h5'>No Messages to Read</Typography>
      </Box>
    );
}

export default NoMessagesAnimation;