import React, { useEffect, useState } from "react";
import { Box, Button, Paper, TextField, InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { fetchAllMessages } from "utils/apiRequests";
import { useNavigate } from "react-router-dom";
import LoaderAnimation from "utils/animation/loader";
import PreviewMessageDialog from "components/Messages/messageViewModal";
import Alert from "components/Alert";
import Layout from "components/Layout";
import SearchIcon from "@mui/icons-material/Search";
import ManageMessagesTable from "components/Table/manageMessagesTable";

const ManageMessagesPage = () => {
  const [messages, setMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    severity: "",
    message: "",
  });

  const navigate = useNavigate();

  const getMessages = async () => {
    setLoading(true);
    const result = await fetchAllMessages();
    setMessages(result.data);
    setAllMessages(result.data);
    setLoading(false);
  };

  const handleMessageSearch = (e) => {
    const search = e.target.value.toLowerCase();
    if (!search) {
      setMessages(allMessages);
      return;
    }

    const filtered = messages.filter((message) =>
      message.message_data.subject.toLowerCase().trim().includes(search)
    );
    setMessages(filtered);
  };

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <Layout>
      <Alert
        severity={alertConfig.severity}
        title={alertConfig.message}
        setOpen={setAlertConfig}
        open={alertConfig.show}
      />
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{ mb: 4, width: "10%" }}
            onClick={() => {
              navigate("/newMessage");
            }}
          >
            <AddIcon />
            Compose
          </Button>

          <TextField
            label="Search Messages"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
            variant="standard"
            sx={{ width: "30%", mb: 2 }}
            onChange={handleMessageSearch}
          />
        </Box>

        <Paper sx={{ width: "100%", mb: 2 }}>
          {loading ? (
            <LoaderAnimation />
          ) : (
            <>
              <ManageMessagesTable
                messages={messages}
                setOpen={setOpen}
                setMessage={setMessage}
                setMessages={setMessages}
                setAlertConfig={setAlertConfig}
              />
            </>
          )}
        </Paper>
        <PreviewMessageDialog
          open={open}
          setOpen={setOpen}
          message={message}
          setAlertConfig={setAlertConfig}
          setMessages={setMessages}
        />
      </Box>
    </Layout>
  );
};

export default ManageMessagesPage;
