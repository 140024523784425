import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Typography, Alert } from "@mui/material";
import { AuthContext } from "utils/context/authContext";
import { useNavigate } from 'react-router-dom';

const SignInForm = ({ from }) => {
  const { control, handleSubmit } = useForm();
  const { handleLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);

  const onSubmit = async (data) => {
    const result = await handleLogin(data);
    if (result.success) {
      const redirectPath = from ? from.pathname + from.search : "/messages";
      navigate(redirectPath, { replace: true });
    } else {
      setLoginError(result.message);
    }
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        DecisionVue Message Center
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%", marginTop: 16 }}
      >
        <Controller
          name="username"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Username"
              variant="outlined"
              margin="normal"
              fullWidth
              required
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Password"
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
              required
            />
          )}
        />
        {loginError && (
          <Alert severity="error" style={{ marginTop: 16 }}>
            {loginError}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="primary"
          style={{ marginTop: 16 }}
        >
          Sign In
        </Button>
      </form>
    </>
  );
};

export default SignInForm;
