import React, { useEffect, useState, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import * as DOMPurify from "dompurify";
import {
  Divider,
  Typography,
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewMessageDialog = ({ open, setOpen, message }) => {
  const [recipients, setRecipients] = useState(
    message.clients ? [...message.clients] : []
  );

  const handleRecipientsSearch = (e) => {
    const search = e.target.value.toLowerCase();
    const filtered = message?.clients.filter((client) =>
      client.Name.toLowerCase().includes(search)
    );
    setRecipients(filtered);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const msgBody = document.getElementById("message-body");
    if (msgBody) {
      const clean = DOMPurify.sanitize(message.message_text, {
        USE_PROFILES: { html: true },
      });

      msgBody.innerHTML = clean;
    }
    setRecipients(message.clients ? [...message.clients] : []);
  }, [message]);

  return (
    <>
      <BootstrapDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        sx={{ borderRadius: "20px", maxHeight: "100%" }}
        maxWidth="lg"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{message.subject}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>

        <Grid container>
          <Grid item sm={9}>
            <Divider />
            <DialogContent
              sx={{
                maxHeight: "700px",
                "&::-webkit-scrollbar": {
                  width: "0.5em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#282c34",
                  borderRadius: "40px",
                },
              }}
            >
              <DialogContentText>
                <div id="message-body"></div>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogContentText
              sx={{ display: "flex", direction: "row", gap: 3, mt: 3, p: 2 }}
            >
              <Typography variant="p">
                <strong>Date:</strong> {message.created_at}
              </Typography>
              <Typography variant="p">
                <strong>Expires:</strong> {message.expires_at}
              </Typography>
            </DialogContentText>
          </Grid>
          <Grid item sm={3}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Recipients</Typography>

              <TextField
                size="small"
                placeholder="Search Recipients"
                onChange={handleRecipientsSearch}
                sx={{ height: "10px", borderRadius: "40px" }}
              />
            </Box>

            <List
              sx={{
                overflowY: "auto",
                maxHeight: "700px",
                "&::-webkit-scrollbar": {
                  width: "0.5em",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#282c34",
                  borderRadius: "40px",
                },
              }}
            >
              {recipients.map((client, index) => (
                <ListItem key={index}>
                  <Chip label={client.Name} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </>
  );
};

export default PreviewMessageDialog;
