import React, { useEffect } from "react";
import { Collapse, Alert as MuiAlert } from "@mui/material";

const Alert = ({ title, severity, open, setOpen }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen({ show: false, severity: "", message: "" }); // Collapse the alert after 2 seconds
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [open, setOpen]);

  return (
    <div>
      <Collapse in={open}>
        <MuiAlert severity={severity}>{title}</MuiAlert>
      </Collapse>
    </div>
  );
};

export default Alert;
