import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/newTheme";
import Home from "pages";
import MessagePage from "pages/messages";
import ViewMessagePage from "pages/message";
import NewMessagePage from "pages/newMessage";
import "App.css";
import { AuthProvider } from "utils/context/authContext";
import { MessageProvider } from "utils/context/messageContext";
import PrivateRoute from "components/Layout/privateRoute";
import ManageMessagesPage from "pages/manageMessages";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        <AuthProvider>
          <MessageProvider>
            <Routes>
              <Route
                path="/messages"
                element={
                  <PrivateRoute>
                    <MessagePage />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/message/:id"
                element={
                  <PrivateRoute>
                    <ViewMessagePage />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/newMessage"
                element={
                  <PrivateRoute>
                    <NewMessagePage />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/manage-messages"
                element={
                  <PrivateRoute>
                    <ManageMessagesPage />
                  </PrivateRoute>
                }
                exact
              />
              <Route path="/" element={<Home />} exact />
            </Routes>
          </MessageProvider>
        </AuthProvider>
      </>
    </ThemeProvider>
  );
}

export default App;
