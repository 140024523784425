import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import WSPLogo from "../wsp_rgb.png";
import useIsMobile from "utils/hooks/mobileHook";
import SignInForm from "components/Auth/signin";
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from "utils/context/authContext";

const Home = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const { userAuthenticated } = useContext(AuthContext);

  const from = location.state?.from;
  const queryParams = location.search;

  useEffect(() => {
    if (userAuthenticated) {
      const redirectPath = from
        ? from.pathname + from.search
        : `/messages${queryParams}`;
      navigate(redirectPath, { replace: true });
    }
  }, [userAuthenticated, navigate, from, queryParams]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        height: "100vh",
        backgroundImage:
          "linear-gradient(to bottom right,rgb(179,214,236), rgb(216,230,240), rgb(255,255,255))",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "475px",
          position: "relative",
          borderRadius: "16px",
        }}
      >
        <img
          src={WSPLogo}
          style={{
            height: "140px",
            marginBottom: "30px",
            width: "290px",
            paddingTop: "10px",
            paddingBottom: "2px",
          }}
        />
        <SignInForm from={from} />
      </Box>
    </Box>
  );
};

export default Home;
